import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next'; // Importamos el hook de traducción
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { Link } from "react-router-dom";


const Container = styled(ContainerBase)`
  ${tw`bg-black text-gray-100 -mx-8 -mb-8`}
  background-color: black;
  color: var(--color6);
`;

const BottomSection = styled.div`
  ${tw`w-full flex flex-col lg:flex-row justify-between items-center mt-4 lg:mt-2`}
  border-top: 1px solid var(--color6); /* Mantener el borde superior */
  padding-top: 0.5rem; /* Reducido el padding superior */
  padding-left: 20px; /* Alejar los textos del borde izquierdo */
  padding-right: 20px; /* Alejar los textos del borde derecho */
`;

const Content = styled.div`
  ${tw`max-w-screen-xl mx-auto py-10 lg:py-12`} /* Reducido el padding vertical */
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const LeftSection = styled.div`
  ${tw`flex flex-col items-center lg:items-start`}

  @media (min-width: 1024px) {
    ${tw`flex-row items-start`}
    width: 70%; /* Abarca el 70% del ancho total */
  }
`;

const LogoContainer = styled.div`
  ${tw`flex items-center justify-center lg:justify-start mb-6 lg:mb-0`}
`;

const LogoImg = styled.img`
  ${tw`w-32 h-32 lg:w-48 lg:h-48`} /* Logo más grande en pantallas grandes */
`;

const NavLinks = styled.ul`
  ${tw`flex flex-col items-center lg:items-start lg:ml-10`} /* Listado de enlaces uno sobre otro */
  list-style: none;
  text-align: left; /* Justificar los textos a la izquierda */
  
  @media (max-width: 768px) {
    text-align: justify; /* Justificar los textos en móviles */
  }
`;

const StyledNavLink = styled(Link)`
  ${tw`text-sm lg:text-base text-gray-300 hover:text-gray-400 transition duration-300 mt-2`}
  font-family: 'KoHo', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 1rem;
  }
`;



const CopyrightText = styled.p`
  ${tw`text-center lg:text-left text-sm text-gray-500 mt-10 lg:mt-4`}
  font-family: 'KoHo', sans-serif;
  padding-left: 20px; /* Alejar el texto del borde izquierdo */
  padding-right: 20px; /* Alejar el texto del borde derecho */

  @media (min-width: 1024px) {
    font-size: 0.875rem; /* Texto más pequeño en pantallas grandes */
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Texto ligeramente más grande en móviles */
  }
`;

const RightSection = styled.div`
  ${tw`mt-10 lg:mt-0 text-center lg:text-right`}

  @media (min-width: 1024px) {
    width: 30%; /* Abarca el 30% del ancho total */
  }
`;

const DesignedBy = styled.p`
  ${tw`text-gray-300 underline hover:text-gray-400 transition duration-300 mt-4 lg:mt-0`}
  font-family: 'KoHo', sans-serif;
  padding-left: 20px; /* Alejar el texto del borde izquierdo */
  padding-right: 20px; /* Alejar el texto del borde derecho */

  @media (min-width: 1024px) {
    text-align: right; /* Justificado a la derecha en pantallas grandes */
    font-size: 0.875rem; /* Texto más pequeño en pantallas grandes */
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Incrementa el tamaño del texto en móviles */
  }
`;

export default () => {
  const { t } = useTranslation(); // Usamos el hook de traducción

  return (
    <Container>
      <Content>
        <LeftSection>
          <LogoContainer>
            <LogoImg src="/logochocowhite.webp" alt={t('footer.logo_alt')} /> {/* Traducción del texto alternativo */}
          </LogoContainer>
          <NavLinks>
          <StyledNavLink to="/">{t('footer.nav_home')}</StyledNavLink>
<StyledNavLink to="/servicios">{t('footer.nav_services')}</StyledNavLink>
<StyledNavLink to="/quienes-somos">{t('footer.nav_about_us')}</StyledNavLink>
<StyledNavLink to="/ubicacion">{t('footer.nav_location')}</StyledNavLink>
          </NavLinks>
        </LeftSection>
        <RightSection>
          {/* Puedes poner otros contenidos en RightSection si es necesario */}
        </RightSection>
      </Content>
      <BottomSection>
        <CopyrightText>
          &copy; 2024 {t('footer.company_name')}. {t('footer.rights_reserved')}
        </CopyrightText>
        <DesignedBy>
          {t('footer.designed_by')} <a href="https://deltasof.com/">Deltasof</a>
        </DesignedBy>
      </BottomSection>
    </Container>
  );
};
